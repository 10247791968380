import React from 'react'

const RubyIcon = props => {
  return (
    <svg
      className="inline fill-current w-4"
      viewBox="-61 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="#ff0059">
        <path d="M285 179l67-12 36-37c-1-3-2-5-5-6L203 3c-2-2-5-3-8-3l-15 91 15 40 31 35zm0 0M105 179l-41-42-62-7-2 6v240l2 6 69-8 34-41 20-82zm0 0M285 333l-53 9-37 39-15 62 15 69c3 0 6-1 8-3l180-121c3-1 4-3 5-6l-43-41zm0 0" />
      </g>
      <path
        d="M195 131V0c-3 0-6 1-8 3L7 124c-3 1-4 3-5 6l103 49zm0 0"
        fill="#ff5e95"
      />
      <path d="M285 333l103 49 2-6V136l-2-6-103 49zm0 0" fill="#e50048" />
      <path
        d="M105 333L2 382c1 3 2 5 5 6l180 121c2 2 5 3 8 3V381zm0 0"
        fill="#ff5e95"
      />
      <path d="M285 179v154l-90 48-90-48V179l90-48zm0 0" fill="#ffa2c3" />
      <path d="M285 179v154l-90 48V131zm0 0" fill="#ff5e95" />
    </svg>
  )
}

export default RubyIcon
