import React, { useEffect, useState } from 'react'
import MysqlIcon from 'src/components/icons/mysql'
import MysqlApi from 'src/api/mysql'
import Spinner from 'src/components/spinner'

const Mysql = () => {
  const [loading, setLoading] = useState(true)
  const [version, setVersion] = useState(null)

  useEffect(() => {
    MysqlApi.getTags().then(res => {
      setLoading(false)
      setVersion(res.data[0].name.split('-').slice(-1)[0])
    })
  }, [])

  return (
    <div className="flex items-center my-1">
      <MysqlIcon />
      <span className="text-xs text-gray-700">
        Mysql {loading ? <Spinner /> : version}
      </span>
    </div>
  )
}

export default Mysql
