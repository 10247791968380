import React, { useEffect, useState } from 'react'
import PostgresIcon from 'src/components/icons/postgres'
import PostgresApi from 'src/api/postgres'
import Spinner from 'src/components/spinner'

const Postgres = () => {
  const [loading, setLoading] = useState(true)
  const [version, setVersion] = useState(null)

  useEffect(() => {
    PostgresApi.getTags().then(res => {
      setLoading(false)
      const tag = res.data.filter(
        tag =>
          tag.name.includes('REL_') &&
          !tag.name.includes('BETA') &&
          tag.name.split('_')[1] >= 12
      )[0]

      setVersion(tag.name)
    })
  }, [])

  return (
    <div className="flex items-center my-1">
      <PostgresIcon />
      <span className="text-xs text-gray-700">
        Postgres {loading ? <Spinner /> : version}
      </span>
    </div>
  )
}

export default Postgres
