import githubClient from 'src/api/githubClient'

export default {
  getLatestRelease() {
    return githubClient.get('/repos/erlang/otp/releases/latest')
  },

  getTags() {
    return githubClient.get('/repos/erlang/otp/tags')
  }
}
