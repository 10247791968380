import React, { useEffect, useState } from 'react'
import ReactIcon from 'src/components/icons/react'
import ReactApi from 'src/api/react'
import Spinner from 'src/components/spinner'

const ReactComponent = () => {
  const [loading, setLoading] = useState(true)
  const [version, setVersion] = useState(null)

  useEffect(() => {
    ReactApi.getLatestRelease().then(res => {
      setLoading(false)
      setVersion(res.data.tag_name)
    })
  }, [])

  return (
    <div className="flex items-center my-1">
      <ReactIcon />
      <span className="text-xs text-gray-700">
        React {loading ? <Spinner /> : version}
      </span>
    </div>
  )
}

export default ReactComponent
