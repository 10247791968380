import React, { useEffect, useState } from 'react'
import NodejsIcon from 'src/components/icons/nodejs'
import NodejsApi from 'src/api/nodejs'
import Spinner from 'src/components/spinner'

const Nodejs = () => {
  const [loading, setLoading] = useState(true)
  const [version, setVersion] = useState(null)

  useEffect(() => {
    NodejsApi.getTags().then(res => {
      setLoading(false)
      const version = res.data
        .filter(data => data.name.includes('LTS'))
        .reduce((p, v) =>
          p.tag_name.split('v')[1] > v.tag_name.split('v')[1] ? p : v
        )
      setVersion(version.tag_name)
    })
  }, [])

  return (
    <div className="flex items-center my-1">
      <NodejsIcon />
      <span className="text-xs text-gray-700">
        Nodejs {loading ? <Spinner /> : version}
      </span>
    </div>
  )
}

export default Nodejs
