import React from 'react'
import Layout from 'src/components/layout'
import Ruby from 'src/components/versions/ruby'
import Postgres from 'src/components/versions/postgres'
import Elixir from 'src/components/versions/elixir'
import Mysql from 'src/components/versions/mysql'
import Firefox from 'src/components/versions/firefox'
import Chrome from 'src/components/versions/chrome'
import Nodejs from 'src/components/versions/nodejs'
import Rails from 'src/components/versions/rails'
import Phoenix from 'src/components/versions/phoenix'
import ReactComponent from 'src/components/versions/react'
import Erlang from 'src/components/versions/erlang'

export default function Home({ data }) {
  return (
    <Layout>
      <div className="flex justify-center">
        <div>
          <Chrome />
          <Elixir />
          <Erlang />
          <Firefox />
          <Mysql />
          <Nodejs />
          <Phoenix />
          <Postgres />
          <Rails />
          <ReactComponent />
          <Ruby />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query GetFile($file: String) {
    file(relativePath: { eq: $file }) {
      id
      name
      absolutePath
    }
  }
`
