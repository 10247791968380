import React, { useEffect, useState } from 'react'
import PhoenixIcon from 'src/components/icons/phoenix'
import PhoenixApi from 'src/api/phoenix'
import Spinner from 'src/components/spinner'

const Phoenix = () => {
  const [loading, setLoading] = useState(true)
  const [version, setVersion] = useState(null)

  useEffect(() => {
    PhoenixApi.getTags().then(res => {
      setLoading(false)
      setVersion(res.data[0].name)
    })
  }, [])

  return (
    <div className="flex items-center my-1">
      <PhoenixIcon />
      <span className="text-xs text-gray-700">
        Phoenix {loading ? <Spinner /> : version}
      </span>
    </div>
  )
}

export default Phoenix
