import React, { useEffect, useState } from 'react'
import ErlangIcon from 'src/components/icons/erlang'
import ErlangApi from 'src/api/erlang'
import Spinner from 'src/components/spinner'

const Erlang = () => {
  const [loading, setLoading] = useState(true)
  const [version, setVersion] = useState(null)

  useEffect(() => {
    ErlangApi.getTags().then(res => {
      setLoading(false)
      const tags = res.data.filter((tag) => /OTP-/.test(tag.name) && !tag.name.includes('rc'))
      setVersion(tags[0].name)
    })
  }, [])

  return (
    <div className="flex items-center my-1">
      <ErlangIcon />
      <span className="text-xs text-gray-700">
        Erlang {loading ? <Spinner /> : version}
      </span>
    </div>
  )
}

export default Erlang
