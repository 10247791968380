import React, { useEffect, useState } from 'react'
import RailsIcon from 'src/components/icons/rails'
import RailsApi from 'src/api/rails'
import Spinner from 'src/components/spinner'

const Rails = () => {
  const [loading, setLoading] = useState(true)
  const [version, setVersion] = useState(null)

  useEffect(() => {
    RailsApi.getRailsGemData().then(res => {
      setLoading(false)
      setVersion(res.data.version)
    })
  }, [])

  return (
    <div className="flex items-center my-1">
      <RailsIcon />
      <span className="text-xs text-gray-700">
        Rails {loading ? <Spinner /> : version}
      </span>
    </div>
  )
}

export default Rails
