import React, { useEffect, useState } from 'react'
import FirefoxIcon from 'src/components/icons/firefox'
import FirefoxApi from 'src/api/firefox'
import Spinner from 'src/components/spinner'

const Firefox = () => {
  const [loading, setLoading] = useState(true)
  const [version, setVersion] = useState(null)

  useEffect(() => {
    FirefoxApi.getFirefoxVersions().then(res => {
      setLoading(false)
      setVersion(res.data.LATEST_FIREFOX_VERSION)
    })
  }, [])

  return (
    <div className="flex items-center my-1">
      <FirefoxIcon />
      <span className="text-xs text-gray-700">
        Firefox {loading ? <Spinner /> : version}
      </span>
    </div>
  )
}

export default Firefox
