import React, { useEffect, useState } from 'react'
import ChromeIcon from 'src/components/icons/chrome'
import ChromeApi from 'src/api/chrome'
import Spinner from 'src/components/spinner'

const Chrome = () => {
  const [loading, setLoading] = useState(true)
  const [version, setVersion] = useState(null)

  useEffect(() => {
    ChromeApi.getChromeVersions().then(res => {
      setLoading(false)
      setVersion(
        res.data.find(elem => elem.os === 'mac').versions[0].current_version
      )
    })
  }, [])

  return (
    <div className="flex items-center my-1">
      <ChromeIcon />
      <span className="text-xs text-gray-700">
        Chrome {loading ? <Spinner /> : version}
      </span>
    </div>
  )
}

export default Chrome
